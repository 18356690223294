<template>
  <div>
    <form action="" @submit.prevent="onSubmit" v-if="profileData">
      <CRow class="justify-content-between mb-3">
        <CCol sm="auto"
          ><div class="ui-type-display-lg">
            <div class="card-subtitle">
              {{ $t("models.role.userSettings.intro") }}
            </div>
          </div></CCol
        >
      </CRow>

      <CCard class="border-0">
        <CCardHeader>
          <div class="card-title">
            {{ $t("models.role.userSettings.general.title") }}
          </div>
          <div class="card-subtitle">
            {{ $t("models.role.userSettings.general.subtitle") }}
          </div>
        </CCardHeader>
        <CCardBody>
          <div class="d-flex justify-content-center" v-if="loading">
            <CSpinner color="info" />
          </div>

          <!-- desc_short -->
          <CRow>
            <CCol md="12">
              <CTextarea
                :placeholder="$t('models.role.desc_short_info')"
                :label="$t('models.role.desc_short')"
                v-model="profileData.desc_short"
                rows="9"
              />
            </CCol>
          </CRow>

          <!-- info_contact -->
          <CRow>
            <CCol md="12">
              <CTextarea
                :placeholder="$t('models.role.info_contact_info')"
                :label="$t('models.role.info_contact')"
                v-model="profileData.info_contact"
                rows="9"
              />
            </CCol>
          </CRow>

          <!-- desc_zone -->
          <CRow>
            <CCol md="12">
              <CTextarea
                :placeholder="$t('models.role.desc_zone')"
                :label="$t('models.role.desc_zone')"
                v-model="profileData.desc_zone"
                rows="9"
              />
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>

      <CCard class="border-0">
        <CCardHeader>
          <div class="card-title">
            {{ $t("models.role.userSettings.transparency.title") }}
          </div>
          <div class="card-subtitle">
            {{ $t("models.role.userSettings.transparency.subtitle") }}
          </div>
        </CCardHeader>
        <CCardBody>
          <div class="card-heading mt-0">
            {{ $t("models.role.userSettings.transparency.eco.title") }}
          </div>

          <!-- extra.transparency.biodiversity -->
          <CRow>
            <CCol md="12">
              <CTextarea
                :placeholder="
                  $t('models.role.userSettings.transparency.eco.placeholder')
                "
                :label="$t('models.role.userSettings.transparency.eco.label')"
                v-model="profileData.extra.transparency.biodiversity"
                rows="9"
              />
            </CCol>
          </CRow>

          <!-- extra.transparency.soil -->
          <CRow>
            <CCol md="12">
              <CTextarea
                :placeholder="
                  $t(
                    'models.role.userSettings.transparency.eco.soil.placeholder'
                  )
                "
                :label="
                  $t('models.role.userSettings.transparency.eco.soil.label')
                "
                v-model="profileData.extra.transparency.soil"
                rows="9"
              />
            </CCol>
          </CRow>

          <!-- extra.transparency.animals -->
          <CRow>
            <CCol md="12">
              <CTextarea
                :placeholder="
                  $t(
                    'models.role.userSettings.transparency.eco.animals.placeholder'
                  )
                "
                :label="
                  $t('models.role.userSettings.transparency.eco.animals.label')
                "
                v-model="profileData.extra.transparency.animals"
                rows="9"
              />
            </CCol>
          </CRow>

          <!-- extra.transparency.climate -->
          <CRow>
            <CCol md="12">
              <CTextarea
                :placeholder="
                  $t(
                    'models.role.userSettings.transparency.eco.climate.placeholder'
                  )
                "
                :label="
                  $t('models.role.userSettings.transparency.eco.climate.label')
                "
                v-model="profileData.extra.transparency.climate"
                rows="9"
              />
            </CCol>
          </CRow>

          <!-- extra.transparency.cycles -->
          <CRow>
            <CCol md="12">
              <CTextarea
                :placeholder="
                  $t(
                    'models.role.userSettings.transparency.eco.cycles.placeholder'
                  )
                "
                :label="
                  $t('models.role.userSettings.transparency.eco.cycles.label')
                "
                v-model="profileData.extra.transparency.cycles"
                rows="9"
              />
            </CCol>
          </CRow>

          <div class="card-heading">
            {{ $t("models.role.userSettings.transparency.social.title") }}
          </div>
          <!-- extra.transparency.diversification -->
          <CRow>
            <CCol md="12">
              <CTextarea
                :placeholder="
                  $t(
                    'models.role.userSettings.transparency.social.diversification.placeholder'
                  )
                "
                :label="
                  $t(
                    'models.role.userSettings.transparency.social.diversification.label'
                  )
                "
                v-model="profileData.extra.transparency.diversification"
                rows="9"
              />
            </CCol>
          </CRow>

          <!-- extra.transparency.type -->
          <CRow>
            <CCol md="12">
              <CTextarea
                :placeholder="
                  $t(
                    'models.role.userSettings.transparency.social.type.placeholder'
                  )
                "
                :label="
                  $t('models.role.userSettings.transparency.social.type.label')
                "
                v-model="profileData.extra.transparency.type"
                rows="9"
              />
            </CCol>
          </CRow>

          <!-- extra.transparency.team -->
          <CRow>
            <CCol md="12">
              <CTextarea
                :placeholder="
                  $t(
                    'models.role.userSettings.transparency.social.team.placeholder'
                  )
                "
                :label="
                  $t('models.role.userSettings.transparency.social.team.label')
                "
                v-model="profileData.extra.transparency.team"
                rows="9"
              />
            </CCol>
          </CRow>

          <div class="card-heading">
            {{
              $t("models.role.userSettings.transparency.leistungsrechner.title")
            }}
          </div>
          <!-- extra.transparency.diversification -->
          <CRow>
            <CCol md="12">
              <CInput
                :placeholder="
                  $t(
                    'models.role.userSettings.transparency.leistungsrechner.placeholder'
                  )
                "
                :label="
                  $t(
                    'models.role.userSettings.transparency.leistungsrechner.label'
                  )
                "
                v-model="profileData.extra.transparency.regionalwertUrl"
              />
            </CCol>
          </CRow>

          <vue-ladda
            :loading="saving"
            data-style="zoom-in"
            button-class="btn btn-primary mt-3"
            type="submit"
          >
            {{ $t("common.editor.button.save") }}
          </vue-ladda>
        </CCardBody>
      </CCard>
    </form>
  </div>
</template>

<script>
import get from "lodash/get";
import { Query } from "@syncfusion/ej2-data";
import { ApiCall, GetDataManager } from "../../../ds";
import { mapGetters } from "vuex";

export default {
  name: "ProfileInfoNew",

  data() {
    return {
      loading: false,
      saving: false,
      familyRoleData: null,
      profileData: null,
    };
  },

  computed: {
    ...mapGetters("connections", ["currencySymbol"]),
  },

  mounted() {
    this.getFamilyRole();
  },

  methods: {
    defaultExtraFields(data) {
      let newData = data;
      if (
        typeof newData.extra.transparency === "string" ||
        newData.extra.transparency === undefined
      )
        newData.extra.transparency = {};
      if (!data.extra.transparency.hasOwnProperty("biodiversity"))
        newData.extra.transparency.biodiversity = "";
      if (!data.extra.transparency.hasOwnProperty("soil"))
        newData.extra.transparency.soil = "";
      if (!data.extra.transparency.hasOwnProperty("animals"))
        newData.extra.transparency.animals = "";
      if (!data.extra.transparency.hasOwnProperty("climate"))
        newData.extra.transparency.climate = "";
      if (!data.extra.transparency.hasOwnProperty("cycles"))
        newData.extra.transparency.cycles = "";
      if (!data.extra.transparency.hasOwnProperty("diversification"))
        newData.extra.transparency.diversification = "";
      if (!data.extra.transparency.hasOwnProperty("type"))
        newData.extra.transparency.type = "";
      if (!data.extra.transparency.hasOwnProperty("team"))
        newData.extra.transparency.team = "";
      if (!data.extra.transparency.hasOwnProperty("regionalwertUrl"))
        newData.extra.transparency.regionalwertUrl = "";
      return newData;
    },

    getFamilyRole() {
      const self = this;
      this.loading = true;

      const roleId = this.$store.state.role.id;
      const dm = GetDataManager("auth_frole", [], roleId);

      ApiCall(
        dm,
        new Query(),
        (response) => {
          self.loading = false;
          self.familyRoleData = response.result;
          let data = this.defaultExtraFields(self.familyRoleData);
          this.profileData = data;
        },
        (response) => {
          self.loading = false;
        }
      );
    },

    prepareProfileData(data) {
      let profileData = {
        id: this.$store.state.role.id,
        info_contact: data.info_contact,
        desc_zone: data.desc_zone,
        desc_short: data.desc_short,
        extra: data.extra,
      };
      return profileData;
    },

    getRoleById(roleId) {
      const dm = GetDataManager("auth_role", [], roleId);
      return dm.executeQuery(new Query()).then((res) => {
        return res.result;
      });
    },

    onSubmit() {
      const roleId = this.$store.state.role.id;
      const requestParams = this.prepareProfileData(this.profileData);

      this.saving = true;

      GetDataManager("auth_frole")
        .update("", requestParams)
        .then(() => {
          this.saving = false;
          this.getRoleById(roleId).then((data) => {
            this.$store.commit("setRole", data);
          });
          this.$store.dispatch("toaster/add", {
            title: "OK!",
            text: this.$t("role.finance.invoicesSettings.success"),
            color: "success",
            autohide: true,
          });
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
          this.$store.dispatch("toaster/add", {
            title: this.$t("role.finance.invoicesSettings.attention"),
            text: this.$t("role.finance.invoicesSettings.failed"),
            color: "info",
            autohide: true,
          });
        });
    },
  },
};
</script>
